<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">

                        <b-tabs content-class="mt-3">
                            <b-tab title="Pago" active>


                                <b-overlay :show="firebase_associados_pagos.is_load">
                                    <div class="row">
                                        <div class="col-3">
                                            <b-form-select @change="filtroPlanos"  :options="planos" v-model="plano_filter" placeholder=""></b-form-select>
                                        </div>
                                        <div class="col-3">
                                            <b-input placeholder="Busca..." v-model="firebase_associados_pagos.search"></b-input>
                                        </div>
                                        <div class="col-1">
                                            <b-button v-on:click="buscaPago" variant="primary">Pesquisar</b-button>
                                        </div>
                                        <div class="col-3">
                                            <b-pagination-nav align="right"
                                                              @change="onPaginationChangePagos"
                                                              :link-gen="nullPage"
                                                              :value="firebase_associados_pagos.pagina"
                                                              :number-of-pages="firebase_associados_pagos.after.length <= 0 ? 1 : firebase_associados_pagos.after.length"
                                                              use-router
                                                              :per-page="firebase_associados_pagos.por_pagina"
                                            ></b-pagination-nav>
                                        </div>
                                        <div class="col-2">
                                            <a href="/associados/edit/novo" class="btn btn-block btn-primary">Nova empresa</a>
                                        </div>
                                    </div>
                                    <b-table :items="firebase_associados_pagos.dados" :fields="colunas">
                                        <template v-slot:cell(botao)="data">
                                            <modalCnpj :item="data.item"></modalCnpj>
                                            <router-link style="margin: 0 10px" class="btn btn-success" :to="{ name: 'AssociadoEdit', params: { cnpj: data.item.empresa.cnpj }}"><i class="fa fa-edit"></i></router-link>
                                            <a style="margin:5px 10px" class="btn  btn-primary" :href="url_vindi+'admin/customers/search?utf8=✓&query='+data.item.empresa.cnpj.replace(/[^0-9]/g, '')" target="_blank"><i class="dripicons-link"></i> vindi</a> {{ data.item.name }}
                                            <status @value="callbackStatus" :obj="data.item"></status>
                                        </template>
                                        <template v-slot:cell(pago)="data">

                                            <span class="btn btn-success" v-if="data.item.pago && data.item.pago == true">Pago</span>
                                            <span class="btn btn-danger" v-if="!data.item.pago || (data.item.pago && data.item.pago == false)">Pendente</span>

                                        </template>
                                        <template v-slot:cell(empresa)="data">

                                            <strong>{{ data.item.empresa.cnpj | VMask(['##.###.###/####-##']) }}</strong><br>
                                            {{ data.item.empresa.razao }}<br>
                                            {{ data.item.empresa.site }}<br>
                                            {{ data.item.empresa.cidade }} - {{ data.item.empresa.estado }}<br>

                                            <textarea class="form-control" v-html="getHtml(data.item.empresa.site)"></textarea>

                                        </template>
                                        <template v-slot:cell(pessoa)="data">

                                            <strong>{{ data.item.pessoa.cpf | VMask(['###.###.###-##']) }}</strong><br>
                                            {{ data.item.pessoa.nome }}<br>
                                            {{ data.item.pessoa.cargo }}<br>
                                            ({{ data.item.pessoa.ddd }}) {{ data.item.pessoa.fone | VMask(['####-#####']) }}

                                        </template>

                                        <template v-slot:cell(contador)="data">
                                            {{ data.index + 1 }}
                                        </template>

                                    </b-table>
                                    <div class="row">
                                        <div class="col-3">
                                        <b-form-select @change="filtroPlanos"  :options="planos" v-model="plano_filter" placeholder=""></b-form-select>
                                    </div>
                                        <div class="col-3">
                                            <b-input placeholder="Busca..." v-model="firebase_associados_pagos.search"></b-input>
                                        </div>
                                        <div class="col-1">
                                            <b-button v-on:click="buscaPago" variant="primary">Pesquisar</b-button>
                                        </div>
                                        <div class="col-4">
                                            <b-pagination-nav align="right"
                                                              @change="onPaginationChangePagos"
                                                              :link-gen="nullPage"
                                                              :value="firebase_associados_pagos.pagina"
                                                              :number-of-pages="firebase_associados_pagos.after.length <= 0 ? 1 : firebase_associados_pagos.after.length"
                                                              use-router
                                                              :per-page="firebase_associados_pagos.por_pagina"
                                            ></b-pagination-nav>
                                        </div>
                                    </div>
                                </b-overlay>
                            </b-tab>
                            <b-tab title="Pendentes">
                                <b-overlay :show="firebase_associados_nao_pagos.is_load">
                                    <div class="row">
                                        <div class="col-3">
                                            <b-form-select @change="filtroPlanos"  :options="planos" v-model="plano_filter" placeholder=""></b-form-select>
                                        </div>
                                        <div class="col-3">
                                            <b-input placeholder="Busca..." v-model="firebase_associados_nao_pagos.search"></b-input>
                                        </div>
                                        <div class="col-1">
                                            <b-button v-on:click="buscaNaoPago" variant="primary">Pesquisar</b-button>
                                        </div>
                                        <div class="col-4">
                                            <b-pagination-nav align="right"
                                                              @change="onPaginationChangeNaoPagos"
                                                              :link-gen="nullPage"
                                                              :value="firebase_associados_nao_pagos.pagina"
                                                              :number-of-pages="firebase_associados_nao_pagos.after.length <= 0 ? 1 : firebase_associados_nao_pagos.after.length"
                                                              use-router
                                                              :per-page="firebase_associados_nao_pagos.por_pagina"
                                            ></b-pagination-nav>
                                        </div>
                                    </div>
                                    <b-table :items="firebase_associados_nao_pagos.dados" :fields="colunas">

                                        <template v-slot:cell(botao)="data">
                                            <modalCnpj :item="data.item"></modalCnpj>
                                            <router-link style="margin: 0 10px" class="btn btn-success" :to="{ name: 'AssociadoEdit', params: { cnpj: data.item.empresa.cnpj }}"><i class="fa fa-edit"></i></router-link>
                                            <a class="btn  btn-primary" :href="url_vindi+'admin/customers/search?utf8=✓&query='+data.item.empresa.cnpj.replace(/[^0-9]/g, '')" target="_blank"><i class="dripicons-link"></i> vindi</a> {{ data.item.name }}
                                            <status @value="callbackStatus" :obj="data.item"></status>
                                        </template>

                                        <template v-slot:cell(pago)="data">

                                            <span class="btn btn-success" v-if="data.item.pago && data.item.pago == true">Pago</span>
                                            <span class="btn btn-danger" v-if="!data.item.pago || (data.item.pago && data.item.pago == false)">Pendente</span>

                                        </template>
                                        <template v-slot:cell(empresa)="data">

                                            <strong>{{ data.item.empresa.cnpj | VMask(['##.###.###/####-##']) }}</strong><br>
                                            {{ data.item.empresa.razao }}<br>
                                            {{ data.item.empresa.site }}<br>
                                            {{ data.item.empresa.cidade }} - {{ data.item.empresa.estado }}<br>

                                            <textarea class="form-control" v-html="getHtml(data.item.empresa.site)"></textarea>

                                        </template>
                                        <template v-slot:cell(pessoa)="data">

                                            <strong>{{ data.item.pessoa.cpf | VMask(['###.###.###-##']) }}</strong><br>
                                            {{ data.item.pessoa.nome }}<br>
                                            {{ data.item.pessoa.cargo }}<br>
                                            ({{ data.item.pessoa.ddd }}) {{ data.item.pessoa.fone | VMask(['####-#####']) }}

                                        </template>

                                        <template v-slot:cell(contador)="data">
                                            {{ data.index + 1 }}
                                        </template>

                                    </b-table>
                                    <div class="row">
                                        <div class="col-3">
                                            <b-form-select @change="filtroPlanos"  :options="planos" v-model="plano_filter" placeholder=""></b-form-select>
                                        </div>
                                        <div class="col-3">
                                            <b-input placeholder="Busca..." v-model="firebase_associados_nao_pagos.search"></b-input>
                                        </div>
                                        <div class="col-1">
                                            <b-button v-on:click="buscaNaoPago" variant="primary">Pesquisar</b-button>
                                        </div>
                                        <div class="col-4">
                                            <b-pagination-nav align="right"
                                                              @change="onPaginationChangeNaoPagos"
                                                              :link-gen="nullPage"
                                                              :value="firebase_associados_nao_pagos.pagina"
                                                              :number-of-pages="firebase_associados_nao_pagos.after.length <= 0 ? 1 : firebase_associados_nao_pagos.after.length"
                                                              use-router
                                                              :per-page="firebase_associados_nao_pagos.por_pagina"
                                            ></b-pagination-nav>
                                        </div>
                                    </div>
                                </b-overlay>
                            </b-tab>
                        </b-tabs>


                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>

import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import firebase from 'firebase/app';
import modalCnpj from '@/components/modalCnpj';
import algoliasearch from 'algoliasearch';
import status from '@/components/status'
export default {
    name: "associados",
    components: {
        status,
        Layout,
        PageHeader,
        modalCnpj
    },
    data() {
        return {

            config_algoliasearch: process.env.VUE_APP_DEFAULT_ALGOLIASEARCH,
            config_algoliasearch_key: process.env.VUE_APP_DEFAULT_ALGOLIASEARCH_KEY,
            url_vindi: process.env.VUE_APP_DEFAULT_VINDI || 'https://sandbox-app.vindi.com.br/',
            firebase_associados_pagos: {
                search: "",
                dados: [],
                is_load: false,
                pagina: 1,
                por_pagina: 50,
                total_pagina: 1,
                after: [],
            },
            firebase_associados_nao_pagos: {
                search: "",
                dados: [],
                is_load: false,
                pagina: 1,
                por_pagina: 50,
                total_pagina: 1,
                after: [],
            },
            colunas: [
                {key: 'contador', label: ''},
                {key: 'pago', label: 'Pago'},
                {key: 'empresa', label: 'Empresa'},
                {key: 'pessoa', label: 'Responsável'},
                {key: 'botao', label: 'Ações'},
            ],
            title: "Associados",
            items: [
                {
                    text: "Home"
                },
                {
                    text: "Associados",
                    active: true
                }
            ],
            planos : [
                {value: null, text: "Filtro por plano"},
                {value: 1, text: "plano-anual-master"},
                {value: 2, text: "plano-anual-mantenedor"},
                {value: 330894, text: "plano-anual-trial"}
            ],
            plano_filter: null,
        };
    },
    mounted() {


        //
        //
        // //
        // firebase.firestore()
        //     .collection('empresas').where('id_plano_vindi','!=','').where('id_plano_antigo','in',[2,8,'2','8']).get().then((querySnapshot) => {
        //         console.log('total',querySnapshot.size)
        //     querySnapshot.forEach((doc) => {
        //
        //         console.log(doc.id, " => ", doc.data());
        //
        //         firebase.firestore().collection('empresas').doc(doc.id).update({
        //             planoManual: {
        //                 id_plano: 1,
        //                 ativo: 1
        //             }
        //         }, {merge: true}).then(() => {
        //             console.log('ok')
        //         }).catch((error) => {
        //             console.log(error)
        //         })
        //     });
        // });
        //

        this.loadAssociados();
        this.loadAssociadosNaoPagos();


        firebase.firestore()
            .collection('planos').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
                this.planos.push({value: doc.id, text: doc.data().name},);
            });
        });
    },
    methods: {
        callbackStatus(e){
            console.log('callbackStatus',e)
            this.loadAssociados();
            this.loadAssociadosNaoPagos();
        },
        getHostnameFromRegex(url) {
            // run against regex
            url = url.replace("https://", "");
            url = url.replace("http://", "");
            // url = url.replace("www.", "");
            // url = url.replace("/", "");
            // extract hostname (will be null if no match is found)
            return url;
        },
        getHtml(site) {
            site = this.getHostnameFromRegex(site);
            return `<a href="https://selos.abcomm.org/certificado?site=${site}" targer="_blank"><img src="https://us-central1-abcomm-selos.cloudfunctions.net/selo/${site}" width="70" border="0"></a>`
        },
        nullPage() {

        },
        buscaPago() {
            this.firebase_associados_pagos.after = [];
            this.firebase_associados_pagos.pagina = 1;
            this.loadAssociados();
        },
        buscaNaoPago() {
            this.firebase_associados_nao_pagos.after = [];
            this.firebase_associados_nao_pagos.pagina = 1;
            this.loadAssociadosNaoPagos();
        },
        onPaginationChangePagos(page) {
            this.firebase_associados_pagos.pagina = page;
            this.loadAssociados();
        },
        filtroPlanos(){
            this.loadAssociados();
            this.loadAssociadosNaoPagos();
        },
        onPaginationChangeNaoPagos(page) {
            this.firebase_associados_nao_pagos.pagina = page;
            this.loadAssociadosNaoPagos();
        },
        loadAssociadosLoad(consulta) {
            consulta = consulta.limit(this.firebase_associados_pagos.por_pagina);
            consulta.get().then(snapshot => {
                snapshot.docs.forEach(doc => {
                    this.firebase_associados_pagos.dados.push(doc.data());
                });
                this.firebase_associados_pagos.is_load = false;
                if (this.firebase_associados_pagos.dados.length >= this.firebase_associados_pagos.por_pagina) {
                    this.firebase_associados_pagos.after[this.firebase_associados_pagos.pagina] = snapshot.docs[snapshot.docs.length - 1];
                    this.firebase_associados_pagos.total_pagina = this.firebase_associados_pagos.pagina + 1;
                }
            }).catch(error => {
                console.log(error);
                this.firebase_associados_pagos.is_load = false;
            }).finally(() => {
                console.log('finally')
                this.firebase_associados_pagos.is_load = false;
            });
        },
        loadAssociados() {

            try{
                this.firebase_associados_pagos.is_load = true;
                this.firebase_associados_pagos.dados = [];
                var consulta = firebase.firestore()
                    .collection('empresas')
                    .where('pago', '==', true);

                try{
                    if(this.plano_filter){
                        if(this.plano_filter==1 || this.plano_filter==2){
                            consulta = consulta.where('planoManual.id_plano', '==', this.plano_filter);
                        }else{
                            consulta = consulta.where('id_plano_vindi', '==', this.plano_filter);
                        }
                    }
                }catch (e){
                    console.log(e)
                }

                consulta = consulta.orderBy('empresa.razao', 'asc');
                try {
                    var pageBefore = this.firebase_associados_pagos.pagina - 1;
                    var after = this.firebase_associados_pagos.after;
                    if (this.firebase_associados_pagos.pagina > 1 && after[pageBefore]) {
                        consulta = consulta.startAfter(after[pageBefore].data().empresa.razao);
                    }
                } catch (e) {
                    console.log(e);
                }

                if (this.firebase_associados_pagos.search.length > 0) {
                    const client = algoliasearch(this.config_algoliasearch, this.config_algoliasearch_key);
                    const index = client.initIndex('empresas');
                    index.search(this.firebase_associados_pagos.search).then(response => {
                        console.log('response', response);
                        let ids = response.hits.map(item => item.objectID)
                        console.log('ids', ids);
                        if (ids && ids.length <= 0) ids = ['00000000000000'];
                        consulta = consulta.where('empresa.cnpj', 'in', ids.length < 10 ? ids : ids.splice(10));
                        this.loadAssociadosLoad(consulta);
                    }).catch(error => {
                        this.is_loading = false;
                        console.log(error);
                    });
                } else {
                    this.loadAssociadosLoad(consulta);
                }
            }catch (e){
                console.log('>>>>>>>>>>>>>>>>>>>>>>',e)
            }


        },
        loadAssociadosNaoPagosLoad(consulta) {
            consulta = consulta.limit(this.firebase_associados_nao_pagos.por_pagina);
            consulta.get().then(snapshot => {
                snapshot.docs.forEach(doc => {
                    this.firebase_associados_nao_pagos.dados.push(doc.data());
                });
                this.firebase_associados_nao_pagos.is_load = false;
                if (this.firebase_associados_nao_pagos.dados.length >= this.firebase_associados_nao_pagos.por_pagina) {
                    this.firebase_associados_nao_pagos.after[this.firebase_associados_nao_pagos.pagina] = snapshot.docs[snapshot.docs.length - 1];
                    this.firebase_associados_nao_pagos.total_pagina = this.firebase_associados_nao_pagos.pagina + 1;
                }
            }).catch(error => {
                console.log(error);
                this.firebase_associados_nao_pagos.is_load = false;
            }).finally(() => {
                console.log('finally')
                this.firebase_associados_nao_pagos.is_load = false;
            });
        },
        loadAssociadosNaoPagos() {
            try{
                this.firebase_associados_nao_pagos.is_load = true;
                this.firebase_associados_nao_pagos.dados = [];
                var consulta = firebase.firestore()
                    .collection('empresas')
                    .where('pago', '==', false);

                try{
                    if(this.plano_filter){
                        if(this.plano_filter==1 || this.plano_filter==2){
                            consulta = consulta.where('planoManual.id_plano', '==', this.plano_filter);
                        }else{
                            consulta = consulta.where('id_plano_vindi', '==', this.plano_filter);
                        }
                    }
                }catch (e){
                    console.log(e)
                }

                consulta = consulta.orderBy('empresa.razao', 'asc');

                try {
                    var pageBefore = this.firebase_associados_nao_pagos.pagina - 1;
                    var after = this.firebase_associados_nao_pagos.after;
                    if (this.firebase_associados_nao_pagos.pagina > 1 && after[pageBefore]) {
                        consulta = consulta.startAfter(after[pageBefore].data().empresa.razao);
                    }
                } catch (e) {
                    console.log(e);
                }

                if (this.firebase_associados_nao_pagos.search.length > 0) {
                    const client = algoliasearch(this.config_algoliasearch, this.config_algoliasearch_key);
                    const index = client.initIndex('empresas');
                    index.search(this.firebase_associados_nao_pagos.search).then(response => {
                        console.log('response', response);
                        let ids = response.hits.map(item => item.objectID)
                        console.log('ids', ids);
                        if (ids && ids.length <= 0) ids = ['00000000000000'];
                        console.log('ids afters', ids);
                        consulta = consulta.where('empresa.cnpj', 'in', ids.length < 10 ? ids : ids.splice(10));
                        this.loadAssociadosNaoPagosLoad(consulta);
                    }).catch(error => {
                        this.is_loading = false;
                        console.log(error);
                    });
                } else {
                    this.loadAssociadosNaoPagosLoad(consulta);
                }
            }catch (e){
                console.log('>>>>>>>>>>>>>>>>>>>>>>',e)
            }


        }
    }
}
</script>

<style scoped>

</style>