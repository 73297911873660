<template>
    <div style="display: inline-block">
        <b-overlay :show="is_loading" rounded="sm">
        <button v-if="obj && ( (obj.lock == undefined)||(obj.lock && obj.lock=='unlock') )" @click="lock" type="button" class="btn btn-info"><i class="fa fa-lock-open"></i></button>
        <button v-if="obj && obj.lock && obj.lock == 'lock'" @click="unlock" type="button" class="btn btn-danger"><i class="fa fa-lock"></i></button>
        </b-overlay>
    </div>
</template>

<script>
import firebase from 'firebase/app';

export default {
    name: "status",
    props: ['obj'],
    emits: ['value'],
    data() {
        return {
            is_loading: false
        }
    },
    mounted() {
        // firebase

    },
    methods: {
        update(status) {
            const jj = JSON.parse(JSON.stringify(this.obj));
            jj.lock = status == 1 ? 'lock' : 'unlock';
            this.$emit('value', jj);
        },
        lock() {
            console.log('obj', this.obj)
            const cnpj = this.obj.empresa.cnpj.replace(/\D/g, '');
            this.is_loading = true;
            firebase.firestore().collection('empresas').doc(cnpj).update({
                lock: 'lock'
            }, {
                merge: true
            }).then(() => {
                this.is_loading = false;
                this.$bvToast.toast('Empresa bloqueada com sucesso', {
                    title: 'Sucesso',
                    variant: 'success',
                    solid: true
                })
                this.update(1);
            }).catch(() => {
                this.is_loading = false;
                this.$bvToast.toast('Erro ao bloquear empresa', {
                    title: 'Erro',
                    variant: 'danger',
                    solid: true
                })
            });
        },
        unlock() {
            console.log('obj', this.obj)
            const cnpj = this.obj.empresa.cnpj.replace(/\D/g, '');
            this.is_loading = true;
            firebase.firestore().collection('empresas').doc(cnpj).update({
                lock: 'unlock'
            }, {
                merge: true
            }).then(() => {
                this.is_loading = false;
                this.$bvToast.toast('Empresa desbloqueada com sucesso', {
                    title: 'Sucesso',
                    variant: 'success',
                    solid: true
                })
                this.update(0);
            }).catch(() => {
                this.is_loading = false;
                this.$bvToast.toast('Erro ao desbloquear empresa', {
                    title: 'Erro',
                    variant: 'danger',
                    solid: true
                })
            });
        }
    }
}
</script>

<style scoped>

</style>