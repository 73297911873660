<template>
    <div style="display: inline-block">
        <b-button @click="getLogo" v-b-modal="item.empresa.cnpj" variant="info"><i class="fa fa-eye"></i></b-button>
        <b-modal size="xl" :id="item.empresa.cnpj" :title="item.empresa.razao" ok-only>

            <div v-if="plano">
                <h3>{{ plano.name }}</h3>
                <div>{{ plano.plan_items[0].product.description }}</div>
                <div v-if="pagamento">
                    <strong>Status: </strong>{{ pagamento.status.toString().toLocaleUpperCase() }}<br>
                    <strong>Vencimento: </strong>
                        <span v-if="pagamento.next_billing_at">{{ moment(pagamento.next_billing_at).format('DD/MM/YYYY') }}</span>
                        <span v-if="!pagamento.next_billing_at">Não definido (em caso de plano mantenedor ou master, precisa ser definido manualmente)</span>
                    <br>
                </div>
            </div>
            <div v-if="!plano">
                <h3>Sem plano</h3>
            </div>
            <br>
            <br>

            <h4>{{ item.empresa.cnpj | VMask(['##.###.###/####-##']) }}</h4>
            <b-overlay :show="logo_url==false">
                <img v-if="logo_url" :src="logo_url" style="height: 200px; width: auto"/>
            </b-overlay>
            <p>
                <strong>Fantasia: </strong>{{ item.empresa.fantasia }}<br>
                <strong>Razão: </strong>{{ item.empresa.razao }}<br>
                <strong>Cnpj: </strong>{{ item.empresa.cnpj }}<br>
                <strong>Site: </strong>{{ item.empresa.site }}<br>
                <strong>Endereço: </strong>{{ item.empresa.endereco }}, {{ item.empresa.numero }} {{ item.empresa.complemento }}, {{ item.empresa.bairro }}, {{ item.empresa.cidade }} - {{ item.empresa.estado }}<br>
                <strong>Area atuação: </strong>{{ item.empresa.area_atuacao }}<br>
                <strong>Receita: </strong>{{ item.empresa.receita }}<br>
                <strong>Funcionarios qtd: </strong>{{ item.empresa.funcionarios_qtd }}<br>
                <strong>Descrição: </strong>{{ item.empresa.descricao }}
            </p>
            <h5>Responsável</h5>
            <p>
                <strong>Nome: </strong>{{ item.pessoa.nome }}<br>
                <strong>CPF: </strong>{{ item.pessoa.cpf }}<br>
                <strong>Cargo: </strong>{{ item.pessoa.cargo }}<br>
                <strong>E-mail: </strong>{{ item.pessoa.email }}<br>
                <strong>Telefone: </strong>{{ item.pessoa.ddd }} - {{ item.pessoa.fone }}
            </p>





        </b-modal>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';
import moment from "moment";

export default {
    name: "modalCnpj",
    computed: {
        moment() {
            return moment
        }
    },
    props: ['item'],
    data() {
        return {
            logo_url: false,
            plano: null,
            pagamento: null,
        }
    },
    methods: {
        getPlano() {
            var idPlano = 0;
            try {
                if (this.item.id_plano_vindi && this.item.id_plano_vindi > 0)
                    idPlano = this.item.id_plano_vindi;
                else
                    idPlano = this.planoAntigoToNovo(this.item.id_plano_antigo)
            } catch (e) {
                try {
                    idPlano = this.planoAntigoToNovo(this.item.id_plano_antigo)
                } catch (e) {
                    idPlano = this.planoAntigoToNovo(0)
                }
            }

            try{
                if (this.item.planoManual && this.item.planoManual.id_plano && this.item.planoManual.id_plano > 0) {
                    idPlano = this.item.planoManual.id_plano;
                }
            }catch (e) {
                console.log(e)
            }

            console.log('idPlano',idPlano)


            if(idPlano == 1) {
                this.plano = {
                    name: 'plano-anual-master',
                    plan_items: [
                        {
                            product: {
                                description: 'plano-anual-master'
                            }
                        }
                    ]
                }

            }else if (idPlano==2){
                this.plano = {
                    name: 'plano-anual-mantenedor',
                    plan_items: [
                        {
                            product: {
                                description: 'plano-anual-mantenedor'
                            }
                        }
                    ]
                }
            }else if (idPlano==330894){
                this.plano = {
                    name: 'plano-anual-trial',
                    plan_items: [
                        {
                            product: {
                                description: 'plano-anual-trial'
                            }
                        }
                    ]
                }
            }else{
                firebase.firestore().collection('planos').doc(idPlano.toString()).get().then((doc) => {
                    if (doc.exists) {
                        this.plano = doc.data();
                    }else{
                        console.log('plano não encontrado',idPlano)
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            }


            if(idPlano == 1 || idPlano == 2 ){
                this.pagamento = {
                    status: 'active',
                    next_billing_at: null
                }

                try{
                    this.pagamento.next_billing_at = this.item.planoManual.vencimento
                }catch (e){console.log(e)}
            }else{
                const cnpj = this.item.empresa.cnpj.replace(/\D/g, '');
                firebase.firestore().collection('pagamentos').where('customer.code','==',cnpj).orderBy('created_at','desc').limit(1).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.pagamento = doc.data();
                        console.log('pagamento',this.pagamento)
                    });
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            }





        },
        planoAntigoToNovo(id) {
            switch (id) {
                case 1:
                    return 318370
                case 2:
                    return 1
                case 3:
                    return 318369
                case 4:
                    return 2
                case 5:
                    return 2
                case 7:
                    return 318368
                case 6:
                    return 318368
                case 8:
                    return 1
                case 9:
                    return 318369
                case 10:
                    return 2
                case 11:
                    return 318370
                default:
                    return 68482
            }
        },
        getLogo() {
            this.getPlano()
            if (this.logo_url !== false) return;
            window.dataObject = this;
            var storageRef = firebase.storage().ref();
            storageRef.child(this.item.empresa.logo_v1).getDownloadURL().then(function (url) {
                window.dataObject.logo_url = url;
            }).catch(function (error) {
                console.log(error);
            });
        },
    }
}
</script>

<style scoped>

</style>